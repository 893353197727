import Container from "components/Container";
import React from "react";
import { CardNotFound } from "components/Card/CardNotFound";

export const NotFoundData = [
  {
    id: "500",
    title: "Ups, Ada Gangguan yang Terjadi",
    message:
      "Kami sedang membereskan masalah ini. Silahkan kembali ke halaman sebelumnya atau mencoba sesaat lagi",
    src: "/images/icon-warning.png",
    useButton: [
      {
        href: "/",
        label: "Kembali",
        variant: "secondary_transparant",
      },
      {
        href: "/",
        label: "Coba Lagi",
      },
    ],
  },
  {
    id: "404",
    title: "Halaman Tidak Ditemukan",
    message: null,
    src: "/images/icon-404.png",
    useButton: [
      {
        href: "/",
        label: "Kembali ke Homepage",
      },
    ],
  },
];

const Page404: React.FC = () => {
  const notFoundProps = NotFoundData.find((x) => x.id === "404");

  return (
    <div className="">
      <main>
        <Container className="h-screen !max-w-[100vw] flex justify-center px-16">
          <CardNotFound {...notFoundProps} />
        </Container>
      </main>
    </div>
  );
};

export default Page404;
